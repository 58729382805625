@import '../../../../static/scss/variables';

.modal-open{
    #root{
        transition: 600ms filter ease-in-out;
    }
}

#root{
    transition: 400ms filter ease-in-out;
}

// textarea scroll bar style
textarea.note-field::-webkit-scrollbar {
    width: 6px !important;
  }
  
.note-field::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.note-field::-webkit-scrollbar-thumb {
border-radius: 3px;
background-color: $darkgray-9 !important;
}