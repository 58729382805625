.onboarding-form-features {
    .toggle-tooltip-wrapper {
        .form-group {
            .custom-switch {
                .custom-control-label {
                    top: 0 !important;
                    right: 10px !important;
                }
            }
        }
    }
    .custom-svg {
        width: 24px;
        height: 24px;
        path,
        circle {
            fill: var(--primary) !important;
        }
    }
}
