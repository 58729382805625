.col-chat-wrap {
  border-radius: 30px;
}

.chat-list-wrapper {
  width: 360px;
  position: relative;
  max-width: 100%;
  .thumb-vertical {
    height: 102px;
  }
}

.all-chat-section {
  overflow: hidden;
  &.card-rounded {
    padding-left: 0;
  }
}
