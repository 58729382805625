@import '../../../static/scss/variables';

.right-pane-wrapper {
    line-height: 1.375rem;

    .show-detail-arrow{
        cursor: pointer;
        .arrow-left {
            transform: rotate(0deg);
            transition: all ease .8s;
        }
        .arrow-right {
            transform: rotate(180deg);
            transition: all ease .8s;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .btn-sm {
        border-radius: 1rem;
        width: 2rem;
        height: 2rem;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        transition: all ease .3s;
        // border: 1px solid $lightgray-1;

        &:hover {
            background-color: $lightgray-5;
        }

        img,
        svg {
            width: 1.25rem;
            height: 1.25rem;
        }

        i {
            font-size: 1.125rem;
        }
    }

    .dropdown {
        .dropdown-item {
            font-size: 0.875rem;
            color: $primary;
            &:active, &:focus {
                color: #FFF;
                background-color: $primary;
            }
        } 

        &.show {

            .btn-sm,
            .btn-sm:focus {
                background-color: $lightgray-1;
            }
        }
    }
}
