@import '../../../static/scss/variables';

.popover-style {
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  max-height: 80%;
  overflow-y: auto;
}

.dynamic-col-container {
  color: white;
  padding: 2px 10px;

  .check-box-all {
    padding-top: 10px;
    color: $darkgray-1;
    font-size: 13px;
    font-weight: 600;
    border-bottom: 1px solid $darkgray-1;
    padding-bottom: 5px;
  }
  .check-box {
    padding-top: 5px;
    padding-bottom: 5px;
    color: $darkgray-1;
    font-size: 0.8125rem;
    font-weight: 600;
  }
}

.export-container {
  font-weight: 600;
  .menu-option {
    color: $darkgray-1;
    font-size: 0.8125rem;
    padding: 5px 10px;
  }
}

.custom-scrollbar > div:last-child{
  z-index: 1;
  right: 0px!important;
}
.custom-scrollbar:hover > div:last-child{
  opacity: 1!important;
}