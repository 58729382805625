@import "../../../static//scss/variables";
@import "../../../static//scss/mixins";
.dashboard {
  .custom-badge {
    border-radius: 10px;
    padding: 1px 7px;
    color: $white;
    min-width: 60px;
    text-align: center;
  }
  .badge-pause {
    background: $warning;
  }
  .badge-active {
    background: $success;
  }
  .badge-inactive {
    background: $danger;
  }

  .filter-tags {
    .tags-container {
      // display: flex;
      // margin: 10px 5px;
      max-width: -webkit-fill-available;
      // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      .filter-tag {
        // width: max-content;
        // padding: 5px 10px;
        // font-weight: 800;
        // background-color: $primary-4;
        // border-radius: 4px;
        // color: $primary-2;
        // font-family: Lato;
        // font-size: 13px;
        // margin: 0 5px;
        position: relative;
        display: inline-table;

        // font-size: 13px;
        font-size: 0.813rem;
        font-weight: 800;
        line-height: 24px;
        padding: 5px 10px;
        background-color: var(--primary-4);
        // border: thin solid #d2d2d2;
        border-radius: 4px;
        color: var(--primary-2);
        margin: 0 8px 5px 0;
        overflow: hidden;
        animation: fadeIn ease 0.6s;
        transition: background-color 0.8s;
      }
    }
    .tags-note {
      margin: 5px;
      margin-left: 0px;
      background-color: $warning-4;
      border-radius: 4px;
      padding: 3px 5px;
      color: $warning-2;
      font-weight: 400;
      width: fit-content;
      // font-size: 12px;
      font-size: 0.75rem;
    }
    .filter-clear {
      color: $darkgray-1;
      font-family: Lato;
      // font-size: 13px;
      font-size: 0.813rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .btn-addmore {
    // font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 600;
    padding: 2px 10px;
    width: max-content;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 5px 5px;
  }

  .cards-container {
    background: $white;
    border-radius: 4px; // UIUX-1733
    padding: 12px;
    margin-top: 20px;
    .heading {
      color: $darkgray-1;
      font-family: Lato;
      font-size: 1.25rem;
      // font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sub-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(217px, 1fr));
      grid-gap: 14px;
      white-space: nowrap;
    }
  }

  @mixin analytics {
    .card-heading {
      // font-size: 12px;
      font-size: 0.75rem;
      font-weight: 700;
      color: $darkgray-2;
    }

    .card-value {
      color: var(--primary);
      font-family: Lato;
      // font-size: 40px;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
    }

    .card-percentage-up {
      color: $success;
      // font-size: 16px;
      font-size: 1rem;
      font-weight: 700;
    }
    .card-percentage-down {
      color: $danger;
      // font-size: 16px;
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .analytics-card {
    padding: 6px 12px;
    background-color: $white;
    border: 1px solid $lightgray-1;
    border-radius: 4px; // UIUX-1733 
    // width: 196px;
    // height: 85px;

    .analytics-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      // margin-bottom: 0.625rem;
    }
    .heading {
      // font-size: 12px;
      font-size: 0.75rem;
      font-weight: 700;
      color: $darkgray-2;
    }

    .value {
      color: var(--primary);
      font-family: Lato;
      // font-size: 30px;
      font-size: 1.875rem;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
    }

    .percentage-up {
      color: $success;
      // font-size: 16px;
      font-size: 1rem;
      font-weight: 700;
    }
    .percentage-down {
      color: $danger;
      // font-size: 16px;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .analytics-list {
    display: grid;
    grid-template-columns: 4fr 2fr 2fr;
    border-bottom: 1px solid $lightgray-2;
    overflow-x: auto;
    .heading {
      color: $darkgray-2;
      font-family: Lato;
      font-weight: 600;
      // font-size: 13px;
      font-size: 0.813rem;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .value {
      color: $darkgray-1;
      font-family: Lato;
      font-weight: 800;
      text-align: left;
      // font-size: 13px;
      font-size: 0.813rem;
    }
    .count-percentage {
      display: flex;
      grid-column: 3/4;
    }

    .percentage-up {
      color: $success;
      // font-size: 10px;
      font-size: 0.625rem;
      font-weight: 800;
    }
    .percentage-down {
      color: $danger;
      // font-size: 10px;
      font-size: 0.625rem;
      font-weight: 800;
    }
  }
  .agent-image-ctr {
    // padding: 10px 0px 3px 20px !important;
    padding: 5px 0px 3px 15px !important;
  }
  // graph css

  .line-chart-card-container {
    // margin-top: 30px;
    // width: 350px;
    border-radius: 4px; // UIUX-1733
    background: $white;
    min-height: 240px;
    .card-header {
      font-size: 0.8125rem !important;
    }

    // max-height: 245px;
  }

  .graph-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-gap: 16px;
    margin-top: 16px;
    padding-bottom: 1rem;
  }

  .graph-card-header {
    background: $lightgray-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding: 8px 12px;
    color: $darkgray-1;
    // font-size: 13px;
    font-size: 0.813rem;
    font-weight: 700;
    height: 40px;
    .added-cancelled-btns {
      font-size: 0.688rem;
      color: #ececfd !important;
      &:hover {
        // color: #ececfd;
      }
    }
    .canceled-btn-white {
      color: #ececfd;
    }
  }

  .list-card-body {
    height: 200px;
    // overflow-y: scroll;
  }

  .chart-wrapper {
    padding: 10px;
    height: 100%;
  }

  .analytic-list-container {
    .analytics-list {
      padding: 10px 0px 8px 20px;
    }
    // overflow: auto;
    // overflow-y: auto;
    // white-space: nowrap;
    border: none;
    height: 100%;

    &:hover {
    }
    div {
      align-items: center;
      text-decoration: none;
      border-left: none;
      border-right: none;
    }
  }
  .operators-agent-stats {
    width: 77%;
    float: right;
    .input-group-prepend {
      span {
        background: transparent;
        border: none;
        padding: 0px;
      }
    }
  }
  .chat-stats-chart {
    // .graph-card-header {
    //   width: 100%;
    //   display: inline-block;
    .first-tab {
      margin-right: 10px;
      background-color: var(--primary-2);
      border-radius: 4px;
      padding-right: 5px;
      padding-left: 5px;
      span {
        color: #ececfd;
        // font-size: 13px;
        font-size: 0.813rem;
        text-align: left;
        input {
          margin-right: 5px;
        }
      }
    }
    .second-tab {
      margin-right: 10px;
      background-color: var(--primary-2);
      border-radius: 4px;
      padding-right: 5px;
      padding-left: 5px;
      span {
        color: #ececfd;
        // font-size: 13px;
        font-size: 0.813rem;
        text-align: left;
        input {
          margin-right: 5px;
        }
      }
    }
    // }
  }
}
.btn-sort-menu {
  display: flex;
  align-items: center;
}

.filter-button-header {
  display: flex;
  justify-content: flex-end;
  float: right;
}

.share-menu-button {
  margin-left: 15px;
  float: right;
  .show {
    .nav-link {
      background-color: var(--primary-2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.16);
      svg {
        color: #fff;
      }
    }
    .dropdown-menu {
      background-color: #ffffff;
      padding: 0px;
      svg {
        color: $darkgray-2;
        width: 14px;
        height: 14px;
      }
    }
    .dropdown-item {
      padding: 0.1rem 0.5rem;
      margin-bottom: 4px;
      margin-top: 4px;
      .menu-title {
        padding-left: 5px;
        color: $darkgray-2;
        font-family: Lato;
        // font-size: 13px;
        font-size: 0.813rem;
        line-height: 18px;
        text-align: left;
      }
    }
  }
  .nav-item {
    background-color: #fff;
    border-radius: 8px;
    width: 40px;
    height: 40px;
  }
  .dropdown-toggle {
    padding: 0.4rem 0.5rem;
    svg {
      color: $darkgray-2;
    }
    &::after {
      display: none;
    }
  }
}

.dashboard-duration-select {
  .dropdown {
    margin-right: 15px;
    .dropdown-menu {
      .dropdown-item {
        padding: 0.25rem 1.2rem !important;
      }
    }
    &:hover {
      color: #fff;
    }
  }
  .show {
    .btn-primary:hover {
      // background-color: var(--primary-2) !important;
      box-shadow: none;
      border-color: transparent;
      .duration-title-icon {
        svg {
          color: #fff !important;
        }
      }
    }
    .dropdown-toggle {
      background-color: var(--primary-2) !important;
      box-shadow: none;
      border-color: transparent;
      &::after {
        color: #fff !important;
      }
      &:focus {
        // background-color: var(--primary-2) !important;
        box-shadow: none;
        border-color: transparent;
      }
      .duration-title-icon {
        svg {
          color: #fff !important;
        }
        span {
          color: #fff !important;
        }
      }
    }
  }
  display: flex;
  justify-content: flex-end;
  .dropdown-toggle {
    padding: 5px 10px 7px 8px;
    background-color: #fff !important;
    border-radius: 8px;
    color: $darkgray-2 !important;
    font-family: Lato;
    // font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    text-align: left;
    border-color: transparent;
    box-shadow: none;
    svg {
      width: 16px;
      height: 16px;
      color: $darkgray-2;
    }
    span {
      color: $darkgray-2;
      font-family: Lato;
      // font-size: 16px;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      padding-left: 8px;
      padding-right: 5px;
      vertical-align: middle;
    }
    &::after {
      color: $darkgray-2;
      vertical-align: middle;
    }
  }
  .selected-duration {
    .dropdown-toggle {
      background-color: var(--primary-2) !important;
      .duration-title-icon {
        svg {
          color: white;
        }
        span {
          color: white;
        }
      }
      &::after {
        color: white;
      }
    }
  }
}

.start-tour-btn {
  padding: 6px;
  background-color: white;
  box-shadow: none;

  svg {
    margin-right: 10px;
    top: -2px;
    position: relative;
  }
}

.modal-cancel-btn {
  width: 30px;
  padding: 0px;
  color: #fff !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 1;
  background: var(--primary-2) !important;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tour-btn-wrapper {
  text-align: right;
  
}

.slider-modal-wrapper {
  .modal-dialog {
    max-width: calc(100% - 300px);
  }
  .modal-content {
    min-height: calc(100vh - 200px);
  }
  .modal-body {
    height: 200px;
    overflow: hidden;
    div {
      height: 100% !important;
    }
  }
  .modal-footer {
    button {
      padding: 10px 20px !important;
    }
    .dismiss-tour-btn {
      color: var(--primary-2) !important;
      border: 1px solid var(--primary-2) !important;
      background-color: transparent !important;
      margin-right: 15px;
      position: absolute;
      left: 10%;
      &:hover {
        background: transparent !important;
      }
    }
    .skip-tour-btn {
      color: var(--primary-2) !important;
      border: 1px solid var(--primary-2) !important;
      background-color: transparent !important;
      margin-right: 15px;
      &:hover {
        background: transparent !important;
      }
    }
  }
}
.slider-modal-wrapper {
  .modal-footer {
    justify-content: flex-start !important;
    > div {
      width: 100%;
      &.button-wraaper {
        display: flex;
        justify-content: flex-end;
      }
      > span {
        top: 6px;
        position: relative;
        left: 15px;
        color: var(--primary-2) !important;
        font-weight: 600;
      }
    }
  }
}
.switch-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-top: 10px;
  > span {
    top: -5px;
    position: relative;
    left: 15px;
    color: var(--primary-2) !important;
    font-weight: 600;
  }
}

.messaging-dashboard-container {
  height: calc(100vh - 140px);
  background-image: url("/static/images/coming-soon_desktop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 1440px) {
  .dashboard .cards-container .sub-items {
    grid-template-columns: repeat(auto-fill, minmax(197px, 1fr));
    > div {
      white-space: nowrap;
      border: 1px solid $lightgray-1;
      border-radius: 4px; // UIUX-1733 
      background-color: $white;
      .analytics-card {
        border: none !important;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .messaging-dashboard-container {
    height: calc(100vh - 150px);
    background-image: url("/static/images/coming-soon_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (max-width: 550px) {
  .dashboard .cards-container .sub-items {
    // grid-template-columns: repeat(auto-fill, minmax(141px, 0fr));
    // grid-template-columns: repeat(auto-fill, minmax(160px, 0fr));
    grid-template-columns: auto auto;
  }
  .dashboard .analytics-card .value {
    font-size: 20px;
  }
  .dashboard .analytics-card .percentage-up,
  .dashboard .analytics-card .percentage-down {
    font-size: 12px;
  }
  .dashboard .analytics-card .heading {
    line-height: 12px;
    white-space: normal;
  }
  .dashboard .analytics-card {
    padding: 5px;
  }
  .analytics-card div {
    white-space: normal;
    line-height: 15px;
  }
  .custom-legend .custom-legend-block {
    margin-top: 2px;
  }
  div.custom-legend {
    align-items: flex-start;
  }
  .dashboard .graph-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
  .graph-card-header {
    height: 65px !important;
    .card-buttons-container {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      span:first-child {
        margin-left: 0 !important;
      }
    }
  }
  .analytics-title {
    padding-left: 0.5rem;
  }
  .add-more-btn {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 360px) {
  .dashboard .cards-container .sub-items {
    // grid-template-columns: repeat(auto-fill, minmax(116px, 0fr));
    grid-template-columns: auto auto;
  }
  .dashboard .graph-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
  }
  .graph-card-header {
    height: 65px !important;
    .card-buttons-container {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      span:first-child {
        margin-left: 0 !important;
      }
    }
  }
  .analytics-title {
    padding-left: 0.5rem;
  }
  .add-more-btn {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 320px) {
  .dashboard .cards-container .sub-items {
    // grid-template-columns: repeat(auto-fill, minmax(96px, 0fr));
    grid-template-columns: auto auto;
  }
  .dashboard .graph-cards-container {
    grid-template-columns: repeat(auto-fill, minmax(232px, 1fr));
  }
  .graph-card-header {
    height: 65px !important;
    .card-buttons-container {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      span:first-child {
        margin-left: 0 !important;
      }
    }
  }
  .analytics-title {
    padding-left: 0.5rem;
  }
  .add-more-btn {
    margin-bottom: 1rem;
  }
}

#sidebar-analytics {
  .select-arrow {
    display: none;
  }
  width: 0px;
  background-color: $lightgray-2;
  // color: #fff;
  top: 70px;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0px 6px;
  // border-radius: 24px 0 0 24px;
  position: absolute;
  @include Transition;
  align-items: flex-start;
  padding: 0;

  &.active {
    width: 28%;
  }

  nav {
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border-radius: 24px 0 0 0;
    border: none;
    a {
      display: inline-block;
      text-align: center;
      padding: 14px;
      text-decoration: none;
      //     min-width: 133px;
    }
  }
}

.dropdown-toggle {
  &:hover,
  &:focus,
  &:active {
    &::after {
      color: #fff;
    }
    .duration-title-icon {
      svg,
      span {
        color: #fff;
      }
    }
  }
}

#sidebar-analytics {
  /* width */
  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $lightgray-2;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lightgray-2;
    border-radius: 6px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $lightgray-4;
  }
}
@media only screen and (max-width: 550px) {
  #sidebar-analytics {
    &.active {
      width: 95%;
    }
    & .collapsible-container .collapse-trigger {
      padding: 0.625rem 0.2rem;
    }
    & .collapsible-nav a {
      padding: 0.625rem 1.8rem;
    }
    & .navbar-ham-right {
      top: 4rem;
      transform: rotate(180deg);
      z-index: 9;
      left: -1px;
      & svg {
        transform: rotate(180deg);
      }
    }
    &.sidebar-nav-close {
      right: 31px;
      & .navbar-ham-right {
        transform: rotate(0deg);
        top: 3rem;
      }
    }
  }

  .mobile_view,
  .editable-fields-list {
    overflow: auto;
  }
  .InovuaReactDataGrid {
    width: 700px;
    overflow-x: scroll !important;
  }
  .inovua-react-toolkit-load-mask {
    position: fixed !important;
  }

  .basic_mobile_view .page-section {
    padding: 1rem 0.5rem;
  }
  .graph-card-header {
    height: 60px;
  }
}

.btn-apply-filter {
  font-size: 15px;
  font-weight: 600;
  width: -webkit-fill-available;
  margin: 10px 5px 5px;
  padding: 3px 10px;
}

.print-preiew {
  display: none;
}

.seprator {
  display: none;
}

.share-menu-button {
  .dropdown-item:active {
    svg,
    .menu-title {
      color: #fff !important;
    }
  }
}

@media print {
  html,
  body {
    // page-break-after: always !important;
    background: white;
  }

  .analytics-header div {
    display: none !important;
  }
  // .share-menu-button,
  // .filter-button-header,
  // .dashboard-duration-select,
  // .filter-menu-button,
  .add-more-btn {
    display: none !important;
  }

  .legends-contain {
    display: flex;
    justify-content: flex-end;
  }

  .dashboard .cards-container {
    margin-top: 10px;
    padding-left: 0px;
  }
  .dashboard .graph-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-gap: 16px;
  }

  // .graph-cards-container.print-graph-cards-container {
  //   display: block;
  // }
  // .graph-cards-container.print-graph-cards-container {
  //   display: block;
  // }

  .card-buttons-container {
    -webkit-print-color-adjust: exact;
  }
  .dashboard .line-chart-card-container {
    border-radius: 10px;
    background: #fff;
    display: inline-block;
    // width: calc(50% - 10px);

    vertical-align: middle;
    min-height: 240px !important;

    // height: 256px;
    //margin-right: 13px;
    border: 1px solid $lightgray-1;
    margin: 5px;
    // margin-top: 10px !important;
    // margin-top: 10px;
    // margin-bottom: 10px !important;
    // &:nth-child(4n) {
    //   margin-right: 0px;
    // }
    // page-break-after: always;
  }

  .dashboard {
    margin: unset 20px;
    .custom-legend {
      -webkit-print-color-adjust: exact;
    }
    .graph-card-header {
      -webkit-print-color-adjust: exact;
      display: flex;
      .full-available-width {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .line-chart-card-container.listing-wrapper {
      // grid-area: 4 / 1 / span 1 / span 2;
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;
    }
    // margin: 10px;
    border: none;
    .list-card-body {
      height: -webkit-fill-available !important;
      display: block !important;
    }
    .analytic-list-container {
      height: 100%;
      .analytics-list {
        display: flex;
        padding: unset 10px !important;
        color: red !important;
        // padding: 0px 10px;
      }
      // overflow: auto;
      // overflow-y: auto;
      // white-space: nowrap;
      border: unset !important;
      height: unset !important;

      &:hover {
      }
      // div {
      //   align-items: unset !important;
      //   text-decoration: unset !important;
      //   border-left: unset !important;
      //   border-right: unset !important;
      // }
    }
  }
  .print-version-wrapper {
    // page-break-after: always !important;
    min-height: auto !important;
    > div {
      height: auto !important;
      > div {
        position: relative !important;
        overflow: hidden !important;
      }
    }
  }

  .print-preiew {
    display: block;
    > div {
      > div {
        position: relative !important;
      }
    }
  }

  .print-preview-scroll {
    min-height: inherit !important;
    height: inherit !important;
    width: -webkit-fill-available;
    // position: relative;
    // margin-bottom: 10px;
    // margin-top: 10px;
    > div {
      position: unset !important;
      overflow: hidden !important;
      margin-bottom: 10px;
      div {
        width: 95%;
      }
    }
  }
  .analytics-list.list-group-item {
    display: block !important;
    > div {
      display: inline-block !important;
      width: 50%;
      &:last-child {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
  .line-height-ext {
    .analytics-list {
      padding: 2px 20px !important;
    }
  }
  .listing-wrapper {
    // padding-right: 40px !important;
    margin-top: 10px !important;
    &:last-child {
      // margin-top: 80px !important;
      //display: none;
    }
  }
  .hide-print-btn {
    display: none !important;
  }
  .seprator {
    height: 1px;
    background: #000;
    display: block;
  }
  .card-wrapper-button {
    padding: 0.25rem;
    border: none;
    width: 70px;
    font-size: 0.6875rem;
    height: 28px;
    box-shadow: none;
  }
}
.active-duration {
  background: var(--primary);
  color: #fff;
}

.status-dot {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin: 5px;
  background: $danger;
}

.card-wrapper-button {
  padding: 0.25rem;
  border: none;
  width: 70px;
  font-size: 0.6875rem;
  height: 28px;
  box-shadow: none;
}
// for without agent images cards list items
.list-item-padding {
  padding: 5px 0px 3px 15px !important;
}
.heading {
  color: $darkgray-2;
  font-family: Lato;
  font-weight: 600;
  font-size: 0.8125rem;
  img {
    width: 24px;
    height: 24px;
  }
}

.agent-image-ctr {
  .value {
    color: $darkgray-1;
    font-family: Lato;
    font-weight: 800;
    text-align: left;
    font-size: 0.8125rem;
  }
  .count-percentage {
    display: flex;
    grid-column: 3/4;
  }

  .percentage-up {
    color: $success;
    font-size: 0.625rem;
    font-weight: 800;
  }
  .percentage-down {
    color: $danger;
    font-size: 0.625rem;
    font-weight: 800;
  }
}
.error-msg {
  color: #b3b2b2;
  font-weight: 600;
  align-items: center;
  display: flex;
  line-height: 0;
  font-size: 0.9rem;
}
.error-border {
  border: 1px solid #fc9e9e !important;
}

.quick-stats-container-min-height {
  min-height: 250px;
}

.none-filtered-graph-note {
  border-radius: 20px;
  background: white;
  color: $warning-2;
  padding: 20px;
  margin: 20px 10px;
}
