body {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  color: $darkgray-1;
}

@mixin smallText {
  font-size: 0.8125rem;
}

@mixin popoverMenu {
  top: 38px;
  right: 0;
  z-index: 1;
  color: var(--primary);
  // width: max-content;
  width: 10.25rem;
  // bottom: -0.5rem;
  position: absolute;
  @include boxShadow;
  transition: all 0.5s ease;
  // padding-top: 0.75rem;
  border-radius: 0.25rem;
  background-color: white;

  &-lg {
    width: 268px;

    @media (max-width: 576px) {
      width: 232px;
    }
  }

  // &:after {
  //   content: '';
  //   width: 10px;
  //   height: 10px;
  //   background: $white;
  //   position: absolute;
  //   left: -4px;
  //   bottom: 22px;
  //   transform: rotate(45deg);
  // }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;

    li {
      padding: 0 1rem;
      margin-bottom: 0.75rem;

      &:not(:last-child) {
        margin-bottom: 0.375rem;
        // border-bottom: 1px solid #eee;
        padding-bottom: 0.375rem;
      }

      a {
        display: flex;
        font-weight: 400;
        font-size: 0.875rem !important;
      }

      svg {
        margin-right: 0.25rem;
      }
    }
  }
}

.lineheight-1 {
  line-height: 1;
}

.text-decoration {
  &-none {
    text-decoration: none !important;
  }

  &-underline {
    &-1 {
      border-bottom: 1px solid;
    }

    &-2 {
      border-bottom: 2px solid;
    }

    &-3 {
      border-bottom: 3px solid;
    }
  }
}
.badge:not(.badge-pill) {
  border-radius: 4px !important; //UIUX-1748
}
.btn {
  font-size: 1rem;
  padding: 1rem 2rem;
  box-shadow: 0 4px 8px 0 rgba($primary-1, 0.24);
  border-radius: 4px !important; // UIUX-1748

  &-lg {
    padding: 1rem 4rem;
  }

  &-flex {
    display: flex;
    align-items: center;
  }

  &-sm,
  &-md {
    padding: 0.25rem 0.4375rem;
    border-radius: 0.5rem;
    @include smallText;
    font-weight: 600;

    svg,
    img {
      width: 0.875rem;
      height: 0.875rem;

      &:not(:last-child) {
        margin-right: 0.375rem;
      }

      &:last-child {
        margin: 0 0.0625rem;
      }
    }
  }

  &-md {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    line-height: 1rem;
  }

  &.btn-primary {
    background-color: var(--primary-2) !important;
    border-color: var(--primary-2) !important ;
    &:hover,
    &:focus {
      background-color: var(--primary) !important;
      &:not(:disabled) {
        box-shadow: 0 0 0 0.2rem var(--primary-light) !important;
      }
    }
  }
  &.btn-outline-primary {
    &:hover,
    &:focus {
      background-color: var(--primary) !important;
      color: #fff !important;
      &:not(:disabled) {
        box-shadow: 0 4px 8px 0 var(--primary-light) !important;
      }
    }
  }
  &.btn-info-1 {
    &:focus,
    &:hover {
      background-color: var(--primary-light) !important;
    }
  }

  &.btn-white {
    background-color: $white !important;
    color: $darkgray-3 !important;
    border-color: $lightgray-1 !important;
    border-radius: 4px !important;
  }
}

// remove box shadow on focus from material dropdown's form-control 
.material-dropdown {
  .form-control {
    &:focus {
      border-color: unset !important;
      box-shadow: unset !important;
    }
  }
}

.form-control {
  height: 56px;
  color: $darkgray-1;
  border-color: $lightgray;

  &:not(.material-select) {
    &:focus,
    &:active {
      color: $darkgray-1;
      border-color: var(--primary) !important;
      box-shadow: 0 0 0 0.2rem var(--primary-light) !important;
    }
  }
  // &:focus,
  // &:active {
  //   color: $darkgray-1;
  //   border-color: var(--primary) !important;
  //   box-shadow: 0 0 0 0.2rem var(--primary-light) !important;
  // }

  &:disabled {
    // conflict styles on other fields like select field in company's contact details
    // background-color: $lightgray-1 !important;
  }

  &-lg {
    padding: 1rem 4rem;
  }

  &.btn-primary {
    background-color: var(--primary-2) !important;
  }

  &.select {
    color: $darkgray-1;
    background-color: $white;
    border-color: $lightgray;
    appearance: none;
    // height: 34px;
    height: 2.125rem;
    font-size: 1rem;

    &:focus {
      border-color: $darkgray-1;
      outline: 0;
      box-shadow: 0 0 0.5rem 0.05rem rgba(var(--primary-3), 0.25);
    }

    &.select + .select-arrow {
      pointer-events: none;
      position: absolute;
      right: 15px;
      top: 38px;
      color: $darkgray-2;
      transition: all 0.1s ease-in-out;
    }

    &.select + .select-arrow.toggled {
      transform: rotate(180deg);
      top: 38px;
      right: 15px;
    }
  }

  @media (max-width: 1199px) {
    height: 48px;
  }

  @media (max-width: 991px) {
    height: 42px;
  }
}

.select-title {
  color: $darkgray-1;
  font-size: 0.8125rem;
  font-weight: 500;
}

.select-wrapper {
  position: relative;
}

.global-loader {
  height: 4px;
  border-radius: unset;
  position: absolute;
  width: -webkit-fill-available;
  z-index: 1;
  box-shadow: 0px 0px 12px $darkgray-3;
}

.custom {
  &-checkbox {
    color: $darkgray-2;
  }

  &-control {
    &-label {
      &:before {
        // top: 0.15em;
        border-width: 2px;
        border-color: $darkgray-2;
      }

      &:after {
        // top: 0.15em;
      }
    }
  }

  &-switch {
    min-height: 1.875rem;
    padding-left: 2.5rem;

    .custom-control {
      &-label {
        cursor: pointer;

        &:before {
          background: $lightgray;
          border: 0 solid;
          width: 2.5rem;
          height: 1.375rem;
          border-radius: 1rem;
        }

        &:after {
          width: 1.125rem;
          height: 1.125rem;
          left: calc(-2.25rem + 2px);
          top: calc(0.25rem + 2px);
          background: $white;
          border-radius: 1rem;
        }
      }

      &-input {
        &:checked {
          ~ .custom-control-label {
            &::after {
              transform: translateX(1.125rem);
              color: $success;
            }

            &::before {
              //switch control color green
              background-color: $success;
            }
          }
        }

        &:focus {
          ~ .custom-control-label::before {
            box-shadow: none;
          }
        }

        &:disabled:checked {
          ~ .custom-control-label::before {
            background-color: $success;
          }
        }
      }
    }
  }
}

.form {
  &-group {
    position: relative;
    margin-bottom: 2rem;

    &.normal-form-group {
      .form-control[value] {
        box-shadow: none !important;
      }
    }

    .form-label {
      color: $darkgray-1;
      @include Transition;

      &.label-movable {
        position: absolute;
        top: 0;

        @media (max-width: 1199px) {
          top: 11px;
        }

        @media (max-width: 991px) {
          top: 0.5rem;
        }
      }

      &.label-center {
        position: absolute;
        top: 1rem;

        @media (max-width: 1199px) {
          top: 11px;
        }

        @media (max-width: 991px) {
          top: 0.5rem;
        }
      }
    }

    @mixin notEmpty {
      top: 0;
      font-size: 0.75rem;
    }

    .form-control {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }

      &.lined {
        border-width: 0 0 1px 0;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        box-shadow: none !important;

        &:focus + .form-label {
          color: var(--primary);
        }

        &:focus + .form-label,
        &.has-value + .form-label {
          @include notEmpty;
        }

        &:-webkit-autofill + .form-label {
          @include notEmpty;
        }
      }

      &[value] {
        box-shadow: 0 0 0px 1000px #ffffff inset !important;
      }
    }

    &.has-value .form-control + .form-label {
      @include notEmpty;
    }

    .field-error {
      color: $danger-1 !important;
      font-size: 0.75rem;
      margin-top: 0.25rem;
      margin-bottom: 0;
      height: 12px;
      white-space: nowrap;
    }

    &.is-invalid {
      .form-control {
        border-bottom-color: $danger-1;
      }

      .form-label {
        color: $danger-1 !important;
      }
    }
  }
}

.full-height {
  height: 100vh;

  &-min {
    min-height: 100vh;
  }
}

.spinner {
  animation: spin 2s linear infinite;
  font-size: 19px;
  display: inline-block;
  line-height: 1;
  transform-origin: center;
  position: relative;
  top: 2px;

  &.small {
    font-size: 14px;
    top: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Right SIDE BAR
#sidebar-right {
  width: 0px;
  background-color: $lightgray-2;
  // color: #fff;
  top: 70px;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0px 6px;
  border-radius: 4px 0 0 4px; // UIUX-1733
  position: absolute;
  // transition: 0.5s ease all;
  // align-items: flex-start;
  padding: 0;

  &.active {
    width: 70%;
    // nav {
    //   margin-top: 5px;
    //   padding: 5px;
    // }
    .sidebar-wrapper {
      .companies-container {
        display: block;
      }
    }
  }

  .sidebar-wrapper {
    .companies-container {
      display: block;
    }
  }
  nav {
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border: none;

    a {
      display: inline-block;
      text-align: center;
      padding: 8px 8px 8px 10px; //UIUX-1733
      text-decoration: none;
      background-color: white !important;
      border-top-left-radius: 4px; // UIUX-1733
      border-top-right-radius: 4px; // UIUX-1733
      box-shadow: 0 0 3px 0 $lightgray;
      &:not(:first-child) {
        margin-left: 3px;
      }
      &.active {
        background-color: $lightgray-2 !important;
        font-weight: 700;
        border-top: 3px solid var(--primary-2);
      }
    }
  }
}

//SIDEBAR
#sidebar {
  width: 90px;
  background: var(--primary);
  color: $white;
  @include Transition;
  position: relative;
  align-items: flex-start;
  padding: 5.5rem 0 0;
  z-index: 1039;

  & .active-link {
    color: var(--white);
    &:hover {
      color: var(--white) !important;
    }
  }

  &.active {
    width: 90px !important;

    // @media (max-width: 1280px) {
    //   width: 188px;
    // }

    .custom-menu {
      margin-right: -50px;
    }
    .navbar-ham {
      text-align: left !important;
    }
  }

  h1 {
    margin-bottom: 20px;
    font-weight: 700;

    .logo {
      color: $white;
    }
  }

  ul.components {
    padding: 0;
  }

  .sidebar-bottom-menu {
    a {
      color: $darkgray-1;

      &:hover {
        color: var(--primary-1);
      }
    }
  }

  .sidebar-bottom-menu-second-last {
    a {
      color: $darkgray-1;

      &:hover {
        color: var(--primary-1);
      }
    }
  }

  ul {
    li {
      // font-size: 14px;
      font-size: 0.875rem;
      > ul {
        margin-left: 10px;

        li {
          font-size: 14px;
        }
      }

      a {
        display: block;
        color: var(--primary-3);
        text-decoration: none;

        &:hover {
          color: $white;
        }
      }

      .active-link:hover {
        color: var(--primary);
        font-weight: 600;
      }

      &.active {
        > a {
          background: var(--primary-1);
          color: $white;
        }
      }
    }
  }
}

#content {
  width: 100%;
  padding: 0 !important;

  // @media (max-width: 992px) {
  //   padding-left: 24px;
  // }

  min-height: 100vh;
  @include Transition;
  background: var(--primary);

  > .rounded-inner {
    background: $lightgray-5;

    // @media (min-width: 992px) {
    border-radius: 24px 0 0 0px;
    // }
  }
}

.navbar-ham {
  position: absolute;
  // top: 1.3rem;
  // right: -1rem;
  top: 20.8px;
  right: -16px;
  background: $white;
  // width: 2rem;
  // height: 2rem;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  text-align: center;
  box-shadow: 3px 0px 5px rgba($secondary, 0.12);
  padding-top: 2px;
  cursor: pointer;
  z-index: 1;
  svg {
    vertical-align: unset !important;
  }
}

// right side bar
.navbar-ham-right {
  position: absolute;
  // left: -2rem;
  left: -32px;
  top: 2rem;
  background: var(--primary-2);
  // width: 2rem;
  // height: 2rem;
  width: 32px;
  height: 32px;
  border-radius: 3px 0px 0px 3px; // UIUX-1733
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  z-index: 1045;
  svg {
    vertical-align: unset !important;
  }
}

.sidebar {
  &-primary {
    padding-top: 0.5rem;
  }

  &-wrapper {
    width: 100%;
    // height: calc(100% - 10px);
    height: 100%;

    ul {
      padding-left: 0;
      list-style: none;
    }
  }

  &-nav {
    .navbar-logo {
      @media (max-width: 1280px) {
        // width: 40px;
        padding-right: 20px;
      }

      overflow: hidden;
      @include Transition;
      .logo-resizing {
        font-size: 40px;
      }
      img {
        width: 58px;
        transition: 0.3s ease all;

        @media (max-width: 1280px) {
          width: 56px;
        }
      }
    }

    a.sidebar-link {
      width: 60px;
      @include Transition;

      .link-text {
        display: flex;
        justify-content: space-between;
        width: -webkit-fill-available;
        align-items: center;
        font-weight: 600;
      }

      // new portal design UIUX-1378
      .link-text-below-icon {
        font-size: 0.625rem;
        padding-left: 0;
        padding-top: 2px;
        padding-bottom: 2px;
        font-weight: 600;
      }
    }

    // new portal design UIUX-1378
    .sidebar-link-with-below-text {
      flex-direction: column;
      width: 100% !important;
      > svg {
        margin-top: 7px;
        margin-right: 0px !important;
      }
    }

    div.sidebar-link {
      height: 52px;
      margin-bottom: 5px;
      overflow: hidden;
      @include Transition;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      padding: 0 0.25rem;

      span {
        padding-left: 30px;

        @media (max-width: 1280px) {
          font-size: 0.865rem;
        }
      }

      > * {
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 0;
        height: 100%;
        // background: var(--primary-4);
        background: var(--primary-5);
        // border-radius: 0 1rem 1rem 0;
        border-radius: 4px; //UIUX-1733
        // border-left: 0 solid $white;
        opacity: 0;
        @include Transition;
      }

      > svg {
        margin-right: 10px;
        min-width: 24px;
      }
    }

    .active-link {
      .sidebar-link {
        &:after {
          opacity: 1;
          // width: 100%;
          width: 100% !important;
          border-width: 2px;
          margin: 0 auto;
        }
      }
    }

    &-open {
      a.sidebar-link {
        width: calc(100% - 35px);

        @media (max-width: 1280px) {
          width: 148px;
        }

        @media (max-width: 576px) {
          width: calc(100% - 1rem);
        }

        span {
          padding-left: 0;
        }
      }

      .navbar-logo {
        background: $lightgray;
        width: fit-content;
        margin: 0 1rem 1rem;
        padding: 0px 7.5px;
        background: white;
        border-radius: 8px;
        .logo-container {
          min-height: 58px !important;
          width: 58px !important;
        }

        @media (max-width: 1280px) {
          width: fit-content;
        }
      }
    }

    &-close {
      .navbar-logo {
        background: $lightgray;
        width: fit-content;
        margin: 0 0.6rem 1rem;
        padding: 0px 0.4rem;
        background: white;
        border-radius: 8px;

        @media (max-width: 1280px) {
          width: fit-content;
        }
      }
      .logo-resizing {
        font-size: 28px !important;
      }
      .logo-container {
        min-height: 38px !important;
        width: 25px !important;
      }
      .navbar-logo .img-w {
        width: 26px;
      }
    }
  }

  &-inner {
    width: 190px;
  }

  &-bottom {
    &-wrap {
      // left: 18px;
      bottom: 1rem;
      z-index: 1040;
      position: absolute;
      text-align: center;

      .notification {
        position: relative;
        cursor: pointer;

        &-list-item {
          span {
            cursor: pointer;
          }
        }
      }

      .alert-dot {
        right: 1px;
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        border-radius: 0.5rem;
        background-color: $danger;
      }

      .avatar-nav {
        cursor: pointer;
        margin-top: 1rem;

        .userIcon {
          text-align: left;

          .avatar-img,
          .avatar-icon {
            width: 2rem;
            height: 2rem;
            background-size: 100%;
            border: 1px solid $white;
            border-radius: 100%;
            background: var(--primary-1);
          }

          .userText {
            font-size: 0.875rem;
            color: $white;
            line-height: 14px;
            margin-left: 10px;
          }
        }
      }
    }

    &-menu {
      @include popoverMenu;

      &-open {
        @include popoverMenu;
        left: 226px;
      }
    }

    // &-menu-second-last {
    //   @include popoverMenu;
    //   // bottom: 2.1rem;

    //   &-open {
    //     @include popoverMenu;
    //     // bottom: 2.4rem;
    //     left: 226px;
    //   }
    // }
  }
}

.userTextPrimary {
  font-size: 0.875rem;
  color: var(--primary-3);
  line-height: 14px;

  &:hover {
    color: $white;
  }
}

.scrollbar {
  &-rail {
    border-radius: 10px;
    background: rgba(white, 0.5);
    @include Transition;
  }

  &-wrap {
    > div:first-child {
      &[style*='overflow: scroll'] {
        overflow: auto !important;
      }

      &[style*='margin-right: -17px'] {
        // padding-right: 17px;
      }

      &[style*='margin-right: -15px'] {
        padding-right: 15px;
      }
    }

    &:hover {
      .scrollbar-rail {
        background-color: rgba(white, 1);
      }
    }
  }
}

.main-head {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.sub-head {
  // font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.page {
  &:not(.has-sidebar) {
    padding: 32px;
  }

  &.has-sidebar {
    .page-header {
      padding: 40px 32px;

      .main-head {
        margin-bottom: 0;
        // width: 280px; //UIUX-1741
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .form-control {
          padding: 2px 8px;
          line-height: 40px;
          height: 44px;
        }
      }
    }
  }
}

.rounded-card {
  border-radius: 0.5rem;
}

.page {
  .go-back {
    cursor: pointer;
  }

  &-content {
    background-color: $lightgray-3;
  }

  &-header {
    background-color: $lightgray-2;
  }

  &-body {
    background: $white;

    &.has-shadow {
      @include boxShadow;
    }
  }

  &-section {
    border-top: 1px solid $lightgray-1;
    padding: 1rem 2rem;
    margin-top: 0.25rem;

    &:first-child {
      padding-top: 2rem;
      margin-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid $lightgray-1;
      padding-bottom: 1.75rem;
    }

    height: inherit;
  }

  &-sidebar {
    width: 324px;
    padding: 1.5rem 0;
    border: 1px solid $lightgray-1;
    background: $lightgray-2;
    font-size: 0.9375rem;
  }

  .company-id {
    font-size: 0.8125rem;
    font-weight: 600;
    color: $darkgray-2;
  }
}

.btn-set {
  display: flex;

  .btn:not(:last-child) {
    margin-right: 1rem;
  }
}

.section {
  &-head {
    margin-bottom: 1rem;
  }
}

.editable {
  &-fields-list {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    font-weight: 600;
    flex-wrap: wrap;

    li {
      font-size: 0.8125rem;
      line-height: 1.75rem;

      &.range {
        .form-control {
          width: 104px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      // margin-bottom: .5rem;

      &:nth-child(odd) {
        width: 243px;
        color: $darkgray-2;
      }

      &:nth-child(even) {
        display: flex;
        align-items: center;
        color: $darkgray-1;
        width: calc(100% - 243px);
      }

      .list-icon-after-text {
        width: 0.875rem;
        height: 0.875rem;
        // margin-left: 0.25rem;
      }

      .list-icon-before-text {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
      }

      .form {
        &-label {
          margin-bottom: 0;
        }

        &-control {
          height: 24px;
          width: 200px;
          @include smallText;
          padding: 0 0.25rem;
          font-weight: 600;

          //&:read-only,
          &[readonly],
          &.read-only,
          &:disabled {
            appearance: none;
            border-color: transparent;
            background: transparent;
            box-shadow: none;
            pointer-events: none;
          }
        }

        &-textarea {
          height: 72px;
          overflow-y: auto;
          resize: none;

          &:disabled,
          &.read-only {
            border-bottom: 1px solid $lightgray-1;
            border-radius: 0;
            pointer-events: initial;
          }
        }
      }

      &.is-invalid {
        .form-control,
        input,
        .form-control::placeholder,
        input::placeholder {
          color: $danger;
          border-color: $danger;
        }
      }
    }
  }
}

.tooltip {
  &-error {
    color: $white;
    background: $danger;
    padding: 0 0.375rem;
    border-radius: 0.25rem;
    @include smallText();
    line-height: 1.5rem;
    position: relative;
    font-weight: 400;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      top: 0.5rem;
    }

    &.tooltip {
      &-bottom {
        margin-top: 0.125rem;

        &:before {
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent $danger transparent;
          top: -5px;
          right: 1rem;
        }
      }

      &-right {
        margin-left: 0.625rem;

        &:before {
          left: -5px;
          border-width: 0.25rem 5px 0.25rem 0;
          border-color: transparent $danger transparent transparent;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

// scrollbar design
#sidebar-right,
.widget-setting-container {
  /* width */
  ::-webkit-scrollbar {
    height: 5px;
  }
  .users-container,
  .companies-container {
    ::-webkit-scrollbar {
      height: unset !important;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $lightgray-2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lightgray-4;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb {
    background: $lightgray-4;
  }
}

.copy-button-icon {
  border: none;
  background: none;
  position: absolute;
  right: 0px;
  color: $darkgray-3;
  outline: none;
  display: none;
  top: 50%;
  transform: translateY(-50%);
}

.copy-button {
  padding: 0px !important;

  .copy-button-wrapper {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px;
    overflow: hidden;
    width: calc(100% - 30px);
  }

  &:hover {
    .copy-button-icon,
    .copied-text-svg {
      display: block;
    }
  }
}

.copied-text-svg {
  color: lightseagreen;
  position: absolute;
  right: 5px;
  display: none;
  top: 50%;
  transform: translateY(-50%);
}

.warning-box {
  background-color: $warning-4;
}

.rounded-border {
  border: 1px solid #ccc;
  padding: 0px;
  margin: 0px 20px;
  border-radius: 4px !important; // UIUX-1733 Changed from 8px to 0px
}

.angle-icon {
  color: var(--primary-4);
}

.warning-box {
  background-color: $warning-4;
}

.rounded-border {
  border: 1px solid #ccc;
  padding: 0px;
  margin: 0px 20px;
  border-radius: 6px;
}

.tooltip-container {
  max-width: -webkit-fill-available;
  align-items: center;

  // display: flex;
  .wrap-text {
    word-wrap: break-word;
    // minWidth: 200px;
  }
}

.provisioning-table-container {
  min-height: calc(100vh - 210px) !important;
  border-radius: 0px 0px 4px 4px !important; // UIUX-1733 Changed from 0 0 10px 10px to 0px
  box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.myreports-table-container {
  min-height: calc(100vh - 225px) !important;
}

.warning-msg {
  color: $warning-2;
  background: $warning-4;
  padding: 8px;
  font-size: 10pt;
  border-radius: 8px;
  margin-bottom: 15px;
}

.section-business-hours {
  width: max-content;
  border: 1px solid $lightgray;
  border-radius: 4px;
  margin: 5px 0 15px 0;

  &-header {
    background-color: $lightgray-2;
    border-radius: 4px 4px 0 0;
    padding: 12px 16px;
    color: $darkgray-1;
    font-family: Lato;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    font-weight: 600;
  }

  &-body {
    padding: 16px;
  }
}

@media only screen and (max-width: 1440px) {
  .page-sidebar {
    width: 255px;
  }

  .editable-fields-list li:nth-child(odd) {
    width: 180px;
  }

  #sidebar-right {
    &.active {
      width: 70%;
      .sidebar-wrapper {
        .companies-container {
          display: none;
        }
      }
    }
    .sidebar-wrapper {
      .companies-container {
        display: none;
      }
    }
  }

  .navbar-ham-right {
    top: 4rem;
  }
}

@media only screen and (max-width: 550px) {
  #sidebar-right {
    &.active {
      width: 95%;
      .sidebar-wrapper {
        .companies-container {
          display: block;
        }
      }
    }
    .sidebar-wrapper {
      .companies-container {
        display: none;
      }
    }
    & .collapsible-container .collapse-trigger {
      padding: 0.625rem 0.2rem;
    }

    & .collapsible-nav a {
      padding: 0.625rem 1.8rem;
    }

    & .navbar-ham-right {
      top: 4rem;
      transform: rotate(180deg);
      z-index: 9;
      left: -1px;

      & svg {
        transform: rotate(180deg);
      }
    }

    &.sidebar-nav-open {
      .sidebar-wrapper {
        .companies-container {
          display: block;
        }
      }
    }

    &.sidebar-nav-close {
      right: 31px;

      & .navbar-ham-right {
        transform: rotate(0deg);
        top: 3rem;
      }

      .sidebar-wrapper {
        .companies-container {
          display: none;
        }
      }
    }
    .editable-fields-lists {
      display: block !important;
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .mobile_view,
  .editable-fields-list {
    overflow: auto;
  }

  .InovuaReactDataGrid {
    width: 700px;
    overflow-x: scroll !important;
  }

  .inovua-react-toolkit-load-mask {
    position: fixed !important;
  }

  .basic_mobile_view .page-section {
    padding: 1rem 0.5rem;
  }
}

//switch button style

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-2) !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-2) !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.modal {
  padding-left: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.introjs-tooltip.introjs-floating {
  max-width: 766px !important;
}

.customTooltip {
  margin: 0 !important;
  transform: translate(-50%, -50%);
  width: 800px !important;
  text-align: center;
  max-width: 800px !important;
  min-width: auto !important;
  padding: 16px;

  h1.introjs-tooltip-title {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.tooltip.show {
  opacity: 1;

  & .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: #333333;
  }
}

.my-tooltip {
  z-index: 10000;
  > .tooltip-arrow {
    border-top: 5px solid #333333;
  }
}

.main-bg {
  background: #efeffb;
  position: relative;

  .bg-theme-wave-container {
    -webkit-mask-image: url('/static/images/wave.svg');
    -webkit-mask-repeat: no-repeat;
    background-position: bottom;
    -webkit-mask-position: center bottom -50px;
    background-color: var(--primary-2);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
  }
}

.login-form-container {
  // background-image: url('/static/images/wave.svg');
  // background-repeat: no-repeat;
  // background-position: bottom;
  // background-position: center bottom -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: inherit;

  .login-form-wrapper {
    width: 500px;

    .login-title {
      text-align: center;
      font-size: 30px !important;
    }

    a.link-logo {
      // width: 80px;
      display: flex;
      margin: 0 auto 25px auto;
      justify-content: center;
      align-items: center;
      .logo-container {
        min-height: 58px;
      }

      img {
        max-width: 100%;
      }
      .logo-resizing {
        font-size: 58px !important;
      }
    }

    .form-container {
      box-shadow: 0px 0px 12px $darkgray-8;
      padding: 30px;
      border-radius: 10px;
      background: #fff;
      .login-form {
        .form-group {
          margin-bottom: 1rem !important;
        }
      }
    }
  }
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

.image-wrapper {
  width: 60%;
  margin: 30px auto;
  background: #fff;
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: 20px;
}

.finish-tour-modal {
  width: 500px;
}

.sso-no-data {
  // height: 100vh;
  height: calc(100vh - 130px);
}

.editable-fields-lists {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 30px;
  align-items: center;
  > li {
    width: 100%;
    // display: inline-block;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    // vertical-align: middle;

    // &:nth-child(odd) {
    //   margin-right: 10px;
    // }

    // &:nth-child(even) {
    //   margin-left: 10px;
    // }

    .form-group {
      display: inline-block;
      // width: calc(100% - 30px);
      width: 100%;
      margin-top: auto;
      margin-bottom: 0rem;
      input[type='date'] {
        height: 26px !important;
        margin-top: 25px !important;
      }
      input {
        height: 34px !important;
      }
    }
  }
}

.material-dropdown {
  display: inline-block;
  // width: calc(100% - 30px);
  width: 100%;
  // top: 5px;
  .is-invalid {
    border-color: #bf0711 !important;
    background-image: unset !important;
  }
  select {
    border: none !important;
    border-bottom: 1px solid #c4cdd5 !important;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px;
    box-shadow: none !important;
  }
}

.active-added-color {
  color: $success;
  font-weight: 600 !important;
  font-size: 0.8125rem;
  font-weight: bold;
  font-family: Lato;
}
.inactive-added-color {
  color: $darkgray-1;
  font-weight: 600 !important;
  // font-size: 13px;
  font-size: 0.725rem !important;
  // font-weight: bold;
  font-family: Lato;
}

.paused-color {
  color: $warning;
  font-weight: 600 !important;
  font-size: 13px !important;
  font-weight: bold;
  font-family: Lato;
}

.active-cancelled-color {
  color: $danger;
  font-weight: 600 !important;
  font-size: 13px;
  font-weight: bold;
  font-family: Lato;
}

.subMenu-visibility {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 250px;
  transition: 0.5s;
  overflow: hidden;
}

.toggle-tooltip-wrapper {
  font-size: 0.75rem;
  top: -10px;
  position: relative;
  display: block;

  label {
    display: block;
    position: relative;
    top: 10px;
  }

  .flag-wrapper {
    padding: 0px !important;
    display: inline-block !important;
    vertical-align: middle;
    width: calc(100% - 28px);
    position: relative;
    // margin-bottom: 2rem;
    font-size: 1rem;
    position: relative;
    top: 10px;

    // svg {
    //   width: 18px !important;
    //   height: 18px !important;
    //   margin-right: 5px;
    // }
  }
}

.collapsible-full-width {
  width: calc(100% - 90px) !important;
  // transition: 3s ease all;
}

.collapsible-nav-width {
  width: calc(100% - 245px) !important;
}

.icon-transformation-right {
  transform: none !important;
}

.full-available-width {
  width: -webkit-fill-available;
  width: -moz-available;
  button {
    span {
      font-size: 11px;
    }
  }
}
.display-none {
  width: 0px;
  overflow: hidden;
}

.btn-float-right {
  float: right !important;
}

.list-margin-drop-down {
  margin-top: -24px;
}

.list-margin-toggle {
  margin-top: -40px;
}

.hide-scroll {
  > div {
    overflow: hidden !important;
  }
}

.sidebar-primary {
  // margin-top: 50px;
}

.font-card-wrapper {
  font-size: 11px;
}

.has-diabled {
  input,
  label,
  select {
    color: $darkgray-2 !important;
    pointer-events: none;
  }
}

.mobile_view {
  position: relative !important;
}
.custom-checkbox {
  .custom-control-label::before {
    top: 0.1rem !important;
  }
  .custom-control-label::after {
    top: 0.1rem !important;
  }
}
.rememberMe {
  .custom-checkbox {
    .custom-control-label::before {
      top: 0.3rem !important;
    }
    .custom-control-label::after {
      top: 0.3rem !important;
    }
  }
}
.modal-backdrop.show {
  opacity: 55%;
  background-color: #000000;
} //UIUX-1746
.text-align-right {
  text-align: right !important;
}
.text-align-left {
  text-align: left !important;
}

.label-text-overflow {
  margin-bottom: 1rem;
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiInput-underline:before {
  border-bottom: 1px solid $lightgray !important;
}
.MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid $danger-1 !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid var(--primary-2) !important;
}
.custom-label-styles {
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.8) !important;
  }
}

.MuiSelect-select:focus {
  background-color: white !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(0.4);
  cursor: pointer;
}
.logo404 {
  .logo-container {
    // height: 80px;
    // width: 80px;
    img {
      // height: 100%;
    }
  }
  .logo-resizing {
    font-size: 58px !important;
  }
}

.logo-container {
  display: flex;
  align-items: center;
}

// override primary variables start

.text-primary-2 {
  color: var(--primary-2) !important;
}
.custom-control-input:checked ~ .custom-control-label:before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--primary);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--primary-light);
}
.progress-bar {
  background-color: var(--primary);
}

.MuiRadio-colorPrimary,
.MuiCheckbox-colorPrimary {
  color: var(--primary) !important;
}
a {
  color: var(--primary);
}

.dropdown-item:active {
  background-color: var(--primary-2) !important;
}

// override primary variables end
