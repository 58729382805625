@import '../../static/scss/variables';

  // Define vars we'll be using
  $brand-success: #5cb85c;
  $check-thickness: 2px;
  $check-color: $brand-success;
  $error-color: red;
  
  .circle-loader {
    border: $check-thickness solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    animation-name: loader-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: content-box;
  }
  
  .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
  }
  .load-success {
    border-color: $check-color;
  }
  .load-error {
    border-color: $error-color;
  }
  
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  