@import "../../../../static/scss/variables";

.main-coming-soon-container {
    // padding-top:80px;
    height: inherit;
    overflow: hidden;
    .inner-container {
        margin: 12px 0px;
        background-color: $white;
        border-radius: 0px; // UIUX-1733 Changed from 15px to 0px
        height: calc(100% - 30px);
        .special-message-text {
            font-size: 1.3rem;
        }
        .special-message-text-mobile {
            font-size: 1rem;
        }
    }
    .MD-signup-image {
        // display: grid;
        // grid-template-columns: repeat(10,1fr);
        //new way
        display: flex;
        // position: relative;

        // new way-2
        // content: ' ';
        // display: table;
        // clear: both;
        min-height: calc(100vh - 300px);

        .image-rendering {
            filter: drop-shadow(5px 5px 8px #222);
            image-rendering: -moz-crisp-edges; // Firefox /
            image-rendering: -o-crisp-edges; // Opera /
            image-rendering: -webkit-optimize-contrast; // Webkit (non-standard naming) /
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor; // IE (non-standard property) /
        }
        .mobile-img {
            position: absolute;
            width: 20%;// UIUX-1523 Changed from 22% to 20% for scroll issue
            right: 0;
            top: 8%;
        }
        .desktop-img {
            // grid-column: 2/span 9;
            // grid-row: 1;
            // padding-top: 15%
            //new way
            width: 85%;// UIUX-1523 Changed from 90% to 85% for scroll issue

            //new way-2
            // float: left;
            // width: 90%;
        }
    }
    .MD-signup-image-no-form {
        max-width: 73%;
        margin: 0 auto;
        // display: grid;
        // grid-template-columns: repeat(15,1fr);
        // position: relative;
        // filter: drop-shadow(5px 5px 8px #222);
        // .mobile-img{
        //     grid-column: 3/span 3;
        //     grid-row: 1
        // }
        // .desktop-img{
        //     grid-column: 5/span 9;
        //     grid-row: 1;
        //     padding-top: 8%
        // }
    }
    .image-section-on-mobile {
        max-width: 64% !important;
    }
    .MD-signup-form-container {
        // box-shadow: 0 4px 8px 0 #222;
        // border-radius: 8px;
        .form-container {
            box-shadow: 0 4px 8px 0 #222;
            // box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
            background-color: #fff;
            border-radius: 8px;
            padding: 23px;
            .form-fields {
                li {
                    display: block;
                    .form-group {
                        margin-bottom: 0.9rem !important;
                        @media only screen and (max-width: 768px) {
                            display: inline-block;
                            width: 100%;
                        }
                        .form-control {
                            height: 4rem;
                            margin-top: 14px !important;
                        }
                        .form-label {
                            font-size: 1rem;
                        }
                        &.has-value {
                            .form-control {
                                font-size: 1rem !important;
                            }
                            .form-label {
                                font-size: 0.75rem;
                            }
                        }
                    }
                    .field-error {
                        position: unset !important;
                        word-break: break-word;
                        white-space: break-spaces;
                        font-size: 0.75rem !important;
                    }
                }
                .signup-btn {
                    border-radius: 8px;
                    padding: 12px 25px;
                    font-size: 1.1rem;
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .form-disabled {
                pointer-events: none !important;
                opacity: 0.6;
            }
        }
        .form-container-loader {
            position: relative;
            .spinner-container {
                position: absolute;
                width: 100%;
                background: transparent;
                top: 0;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                border-radius: 8px;
                .form-spinner {
                    font-size: 5rem;
                    width: 5rem;
                    height: 5rem;
                }
            }
        }
    }

    .on-mobile-screen-header {
        .on-mobile-image {
            image-rendering: -moz-crisp-edges; // Firefox /
            image-rendering: -o-crisp-edges; // Opera /
            image-rendering: -webkit-optimize-contrast; // Webkit (non-standard naming) /
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor; // IE (non-standard property) /
        }
    }
}
