@import '../../../../static/scss/variables';
@import '../../../../static/scss/functions';

.change-leadtype-modal-header {
    color: $darkgray-1;
    font-weight: bold;
    background: $lightgray-2;
    border: none;
    font-family: Lato;
    // font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
  }
  .change-leadtype-modal-header-container {
    height: 62px;
  }