@import '../../../../static/scss/variables';

.chat-details-card {
  font-family: Lato, 'Segoe UI Emoji', serif;
}

.user-type {
  position: absolute;
  bottom: -24px;
  left: 0;
  font-size: 6px;
  color: #fff;
  padding: 4px;
}

.BOT {
  background-color: $warning;
}

.SYSTEM {
  background-color: var(--primary-light);
}

.PRECHAT {
  background-color: $dark;
}

.PRE-DEFINED {
  background-color: $secondary;
}

.POKE {
  background-color: $success;
}

.AGENT {
  background-color: var(--primary);
}

.radio-as-button-wrapper {
  .custom-control {
    &-label {
      text-align: center;
      display: block;
      background: $lightgray-2;
      color: $darkgray-2;
      padding: 0.25rem 0.125rem;
      border-radius: 4px; //UIUX-1748
      transition: 0.3s ease all;
      cursor: pointer;
    }

    &-input {
      ~ .custom-control-label {
        &:before,
        &:after {
          visibility: hidden;
        }
      }

      &:checked {
        ~ .custom-control-label {
          // background: var(--th-light-gray);
          background: var(--primary-2);
          color: #fff;
        }
      }
    }
  }
}

.chat-details-header-container{
  padding: 0.45rem 1rem !important;
  .chat-details-header {
    display: flex;
    line-height: unset !important;
    .chat-header {
      &-visitor-name {
        color: var(--primary);
        font-weight: 700;
        // margin-bottom: -0.7rem;
      }

      &-start-time {
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        flex-flow: wrap;
        .id-span{
          color: var(--primary);
        }
      }
    }

    &-left {
      .chat-back-arrow {
        padding: 0px 4px;
        background-color: red;
        .arrow-left-icon {
          font-size: 16px;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
    }

    // align-items: center;
    // justify-content: center;
  }
}

.user-type {
  position: absolute;
  bottom: -24px;
  left: 0;
  font-size: 6px !important;
  color: #fff;
  padding: 4px !important;
}

.BOT {
  background-color: $warning !important;
}

.SYSTEM {
  background-color: var(--primary-light) !important;
}

.PRECHAT {
  background-color: $dark !important;
}

.PRE-DEFINED {
  background-color: $secondary !important;
}

.POKE {
  background-color: $success !important;
}

.AGENT {
  background-color: var(--primary) !important;
}

.font-color {
  color: var(--primary);
}

.font-color-disabled {
  color: $darkgray-3;
}

.chat_user_avatar {
  .user-avatar {
    width: 28px;
  }
}

.input-action-icon {
  &.emojis-wrap {
    width: 22px;
    margin-left: 12px;
  }

  .action-icon {
    svg {
      color: $darkgray-2 !important;
    }

    &.active {
      svg {
        color: var(--primary) !important;
      }
    }
  }

  .submit-icon svg {
    // color: $primary !important;
  }
}

.chat_message_wrapper {
  &.chat_message_left {
    .start-end-msg {
      border-radius: 0px 22px 22px 22px !important;
    }
    .center-msg {
      border-radius: 0px 22px 22px 0px !important;
    }
  }
  &.chat_message_center {
    display: flex;
    justify-content: center;

    .copy-to-form {
      display: none;
    }
    .alert-msg {
      border-radius: 22px !important;
      font-style: italic;
      color: $darkgray-1 !important;
      padding: 5px 15px !important;
      background: $lightgray-2 !important;
      font-size: 0.9rem !important;
      font-weight: 600;
    }
  }

  &.chat_message_right {
    .start-end-msg {
      border-radius: 22px 0px 22px 22px !important;
    }
    .center-msg {
      border-radius: 22px 0px 0px 22px !important;
    }
  }

  &.chat_message_left,
  &.chat_message_right {
    // agent/operator messages for add suggestion show elipses to open add statement modal
    // and for visitor messages
    .copy-to-form {
      position: absolute;
      right: 0.325rem;
      top: 0.7rem;
      opacity: 0;
      transition: opacity ease 0.3s;
      visibility: hidden;
      cursor: pointer;

      .dropdown-toggle {
        padding: 0;
        box-shadow: none;

        &:after {
          display: none;
        }
      }

      .dropdown-item {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
      }

      .dropdown-menu {
        padding: 0;
        min-width: 100px;
      }
    }

    &:hover .copy-to-form,
    .copy-to-form.opened {
      opacity: 1;
      visibility: visible;
    }
  }
}

.box-shadow-bottom {
  box-shadow: 0 1px 8px #b6b6b6bf;
}

.chat-message-input {
  cursor: text;
  .msg-textarea-field {
    box-sizing: border-box;
    padding: 5px 6px 0px 6px;
    min-height: 70px;
  }
  .mobile-msg-textarea-field {
    min-height: 0px !important;
    padding: 0px 26px 0px 28px;
    z-index: 1;
  }
  .mobile-icons-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 100%;
    span {
      z-index: 1;
      align-self: flex-end;
    }
    .chat-input-actions {
      align-self: flex-end;
    }
  }
  .on-mobile-screen-icons {
    position: absolute;
    left: -5px;
    bottom: 100%;
  }

  .chat-input-actions {
    .mobile-submit-icon {
      font-size: 1.3rem;
      color: var(--primary);
      z-index: 1;
    }
  }
}

.msg-textarea-field ::-webkit-scrollbar {
  width: 6px;
}

.msg-textarea-field ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.msg-textarea-field ::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: $darkgray-9;
}

.chat_box_wrapper {
  .scroll-bar-comp > div:first-child {
    overflow-x: hidden !important;
  }
}
