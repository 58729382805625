@import "../../../../static/scss/variables";

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid $lightgray !important;
}
.MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--primary) !important;
}
.MuiOutlinedInput-root:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
}
