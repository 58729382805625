.history-grid-container {
  height: 100%;
  .InovuaReactDataGrid {
    @media only screen and (max-width: 550px) {
      width: 370px;
      overflow-x: scroll !important;
    }
  }
  .inovua-react-pagination-toolbar__per-page-text {
    display: none !important;
  }
  .inovua-react-toolkit-combo-box {
    display: none !important;
  }

  .inovua-react-scroll-container__view {
    .inovua-react-toolkit-arrow-scroller__inner-wrapper:not(:has(.inovua-react-toolkit-menu__table)),
    .inovua-react-toolkit-toolbar__inner-wrapper {
      justify-content: unset !important;
      display: inline-flex;
      padding-right: 60px !important;
      .inovua-react-toolkit-toolbar__separator:nth-child(2n-1) {
        display: none;
      }
    }
  }

  .InovuaReactDataGrid__header {
    height: 41px !important;
  }

  .InovuaReactDataGrid__header-wrapper {
    .InovuaReactDataGrid__column-header__resize-wrapper {
      display: flex;
      flex-direction: row-reverse;
      height: inherit !important;

      .InovuaReactDataGrid__column-header {
        height: inherit !important;
      }

      .InovuaReactDataGrid__column-header__filter-wrapper {
        width: 94% !important;
        padding-left: 10px;
      }
    }
  }

  .inovua-react-toolkit-menu {
    left: unset !important;
    right: 100% !important;
  }
}

.chat-history-row-container {
  & after {
    height: 2;
    display: block;
    width: 100%;
    background: #808080;
    border-right: 1px white;
    content: "";
  }
  .badge-margin {
    margin: 2px;
  }
  .badge-font {
    font-size: 9px;
  }
  .badge-icon-height-width {
    height: 16px;
    width: 16px;
  }
}
