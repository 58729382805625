@import '../../../../static/scss/variables';

.deleteModal {
  .modal-info-text {
    color: $darkgray-2;
    font-family: Lato;
    font-size: 15px;
    font-weight: 500;
  }
  .submit-btn-text {
    color: $primary-4;
  }

  border-radius: 0.5rem;
  width: 480px;
  .modal-content {
    border-radius: 0.5rem;
    .modal-body {
      padding: 2rem;
      .modal-title {
        color: $black;
        font-weight: 600;
        margin-bottom: 24px;
      }
      .form {
        &-label {
          left: 1rem;
          font-size: pxToRem(13);
          color: $darkgray-2;
        }
        &-group {
          margin-bottom: 1rem;
          .passEye {
            display: none;
          }
          &.has-value {
            .form-label {
              font-size: pxToRem(10);
              color: $darkgray-2;
              top: 0.5rem;
            }
            .form-control {
              padding-top: pxToRem(14);
            }
            .passEye {
              display: inline;
              cursor: pointer;
              right: 0.75rem;
              position: absolute;
              top: 0.625rem;
              color: $darkgray-2;
            }
          }
          &.is-invalid {
            .form-control {
              border-color: $danger-1;
            }
            .form-label {
              color: $danger-1;
            }
          }
          .form-control {
            padding: 0 1rem;
            height: 3rem;
            color: $darkgray-1;
            font-size: pxToRem(13);
            &.lined {
              &:focus + .form-label {
                font-size: 10px;
                top: 0.5rem;
              }
              &.form-control {
                &:focus {
                  padding-top: pxToRem(14);
                }
              }
            }
          }
          .form-control {
            &.lined {
              border-width: 1px;
              border-radius: 0.25rem;
            }
          }
        }
        button {
          margin-top: 1rem;
          span {
            color: red !important;
          }
        }
      }
    }
  }
}
