@import '../../static/scss/variables';

@mixin collapsibleSetup {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.collapsible {
  &-comp-container {
    .collapse {
      &-trigger {
        font-weight: 700;
        @include collapsibleSetup;
        padding: 0.625rem 1rem;
        color: $darkgray-1;
        position: relative;
        white-space: nowrap;
        svg,
        img {
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.5rem;
        }
        .menu-name {
          font-size: 0.9375rem;
        }
        .collapse-caret {
          position: absolute;
          right: 0;
          top: calc(50% - 14px);
          width: 28px;
          height: 28px;
          @include transition(0.3s);
        }

        &.active {
          background: var(--primary-2);
          color: $white;
          text-decoration: none;
          border-radius: 0px; // UIUX-1733 Changed from 5px 5px 0px 0px to 0px
          transition: 0.3s;
          .collapse-caret {
            transform: rotate(180deg);
          }
        }

        &:not(.active) {
          transition: 0.3s;
          svg {
            fill: var(--primary-2);
          }
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      .panel-border {
        border: 1px solid $darkgray-3;
        border-radius: 4px; // UIUX-1733 
      }
    }
  }

  &-nav {
    a {
      @include collapsibleSetup;
      padding: 0.625rem 3.5rem;
      @include transition(0.3s);
      text-decoration: none;
      color: $darkgray-2;
      // white-space: nowrap;

      &:hover,
      &.active {
        color: var(--primary-2);
      }

      &.active {
        font-weight: 700;
      }
    }
  }
}
