@import '../../../static/scss/variables';

.widget-setting-container {
  // stop hiding companies sidebar on large screen above 100% zoom
  .companies-container{
    display: block !important;
  }

  .widget-options-container {
    background-color: $lightgray-2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 2px solid $lightgray-1;

    .font-size-class {
      font-size: 0.9375rem;
    }
  }
  .widget-editor-container {
    // background-color: $white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  // .widgetPreviewContainer {
  //   background-color: deepskyblue;
  // }

  .mobile-view {
    .widget-options-container {
      margin-left: 0;
      left: -100%;
      position: fixed;
      z-index: 999;
      height: 100%;
      top: 0;
      margin-top: 70px;
      transition: all 0.5s ease-in;
    }
    .show-opt-container {
      margin-left: 100% !important;
      position: absolute;
      transition: all 0.5s ease-in;
      width:100%;
      margin-top:70px;
    }
    // .widgetPreviewContainer {
    //   background-color: deepskyblue;
    //   margin-right: 0;
    //   right: -100%;
    //   position: fixed;
    //   z-index: 999;
    //   transition: all 1s ease-in;
    //   height: 100%;
    // }

    // .show-preview-container{
    //   margin-right: 100% !important;
    //   position: absolute;
    //   height: 100%;
    //   transition: all 1s ease-in;
    // }
  }
}
