.userdetails-lead-tabs{
    .disabled-tab{
        border: 1px solid #ccc;
        border-radius: 8px;
    }

}

// userInfo css
.participant-span { 
    mark{
    background-color: yellow !important;
    border-radius: 8px;
    }
}