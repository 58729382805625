@import "../../static/scss/variables";


  .backface-visibility-hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .tile {
    // color: white;
    padding: 0px 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  
  div.button-container {
    float: left;
    width: 100%;
    margin-top: 15px;
  }
  div.button-container button {
    width: auto;
    padding: 7px 22px;
  }
  .flipper-container {
    /* float: left; */
    width: 100%;
    height: 100%;
    margin-right: 15px;
    display: block;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    -ms-perspective: 1000px;
    // .flipper{
    //   overflow: auto;
    // }

    .flip-button{
      color: $primary;
      font-family: "Lato", sans-serif;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      display: block;
    }
  }
  .flipper-container span {
    // color: white;
  }
  .flipper-container > div.flipper {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
  }
  .flipper-container > div.flipper .front,
  .flipper-container > div.flipper .back {
    float: left;
    display: block;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -webkit-transition: -webkit-transform ease 500ms;
    -moz-transition: -webkit-transform ease 500ms;
    -ms-transition: -webkit-transform ease 500ms;
    -o-transition: -webkit-transform ease 500ms;
    transition: -webkit-transform ease 500ms;
    -webkit-transition: transform ease 500ms;
    -moz-transition: transform ease 500ms;
    -ms-transition: transform ease 500ms;
    -o-transition: transform ease 500ms;
    transition: transform ease 500ms;
  }
  .flipper-container > div.flipper .front {
    z-index: 2;
    // background: #19489E;
    /* front tile styles go here! */
  
  }
  .flipper-container > div.flipper .back {
    // padding-top: 2.5rem
    // background: #9E1919;
    /* back tile styles go here! */
  
  }
  .flipper-container.horizontal .front {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
  }
  .flipper-container.horizontal .back {
    transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
  }
  .flipper-container.horizontal div.flipper.flipped .front {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }
  .flipper-container.horizontal div.flipper.flipped .back {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
  }
  .flipper-container.vertical .front {
    transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
  }
  .flipper-container.vertical .back {
    transform: rotateX(-180deg);
    -ms-transform: rotateX(-180deg);
    -webkit-transform: rotateX(-180deg);
    -o-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
  }
  .flipper-container.vertical div.flipper.flipped .front {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
  }
  .flipper-container.vertical div.flipper.flipped .back {
    transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
  }

  