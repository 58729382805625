@import '../../static/scss/variables';

.badge-container {
  position: absolute !important;
  padding: 3px 5px !important;
  font-size: 9px !important;
  background-color: $danger !important;
  color: #fff;
}

.badge-container-color {
  background-color: $danger !important;
}
