.suggestions-questions {
  mark {
    background-color: yellow !important;
  }
  .according-text,
  .chatname-title {
    font-family: 'Lato';
    cursor: pointer;
  }
}
#suggestion-scroll-div {
  .suggestion-input {
    .form-control {
      &:focus,
      &:active {
        box-shadow: none !important;
        border-color: var(--th-light-gray) !important;
      }
    }
  }
}
