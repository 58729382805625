@import '../../../static//scss/variables';

.home-container {
  .whats-new-frame {
    width: 100%;
    min-height: 200px;
    border-radius: 4px; //UIUX-1733
    margin: 10px 35px;
    height: calc(100vh - 365px);
    border: none;
  }

  .card-border {
    border-radius: 4px; //UIUX-1733
    padding: 20px;
  }
  .image-width {
    width: 80%;
  }
  .heading-color {
    color: $primary;
    margin-bottom: 20px;
  }
  .negative-margin {
    margin-top: -20px;
  }
  .detail-button {
    border-radius: 10px;
    background: $primary;
  }
  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 20px;

    .card-container {
      background: $primary;
      color: white;
      padding: 20px 15px;
      position: relative;

      .card-heading {
        margin-left: 5px;
        margin-bottom: 0px;
        font-size: 0.9375rem;
        font-weight: 600;
      }
      .card-body-custom {
        padding: 0px;
        .position-value {
          position: absolute;
          text-align: end;
          width: 65%;
          margin-left: 30%;
          font-size: 7rem;
          opacity: 0.2;
          bottom: -10px;
          line-height: 1;
          right: -10px;
        }
      }
      .value {
        font-size: 30pt;
        //   font-weight: 600;
      }
      .info-msg {
        font-size: 13px;
        //   font-weight: 600;
      }
    }
  }

  @media (max-width: 1024px) {
    .negative-margin {
      margin-top: 20px;
    }
  }
  @media (max-width: 1366px) {
    .negative-margin {
      margin-top: 10px;
    }
  }
}
