.chat-details-right-pane {
    width: 480px;
    max-width: 100%;
    margin-right: -100%;
    transition: all ease-in-out .8s;
  &.show-panel {
    margin-right: 0;
  }
}

.chat-details-right-pane-optIn {
  width: 400px;
  max-width: 100%;
  margin-right: -100%;
  transition: all ease-in-out .8s;
&.show-panel {
  margin-right: 0;
}
}

.chat-details-right-pane {
  .close-panel {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    left: 18px;
    top: 25px;
  }

  .scroll-bar-comp > div:first-child {
    overflow-x: hidden !important;
  }
}

.mobile-wrapper {
  .chat-details-right-pane {
    @media (min-width: 768px) {
      width: calc(100% - 92px);
    }

    @media (max-width: 767px) {
      width: calc(100% - 36px);
    }
    margin-right: 0;
    right: -100%;
    position: fixed;
    z-index: 999;

    &.show-panel {
      right: 15px;
    }
  }
}
