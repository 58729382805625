@import "../../static/scss/variables";


.count-badge{
    position: absolute !important;
    top: 2px;
    left: 48px;
    padding: 4px 6px !important;
    font-size: 10px !important;
    background-color: $danger !important;
    color: #fff;
  }

  .text-badge{
    position: absolute !important;
    bottom: 1px;
    left: 3px;
    padding: 3px !important;
    font-size: 7px !important;
    background-color: $primary !important;
    color: #fff;  
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }


  .count-greater-than-9{
    padding:4px !important
  }


  .incoming-chat-vertical-indicator{
    position: absolute !important;
    bottom: 3px;
    // left: 8px;
    left: 2px;
    padding: 0px 0px 3px 0px !important;
    font-size: 10px !important;
    font-weight: 800 !important;
    color: $primary !important;
    background-color: #ececfd !important; 
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    height: 45px;
    width:10px;
    // display: flex;
    // align-items: flex-end;
    // border:none !important
    border: 1px solid #ccc !important;
    border-radius: 7px;
  }

  .incoming-count-greater-than-9{
    font-size:8px !important
  }
