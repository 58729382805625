.confirm-container{
    position: relative;
    .top-text{
        position: absolute;
        top: -25px;
        left: 16px;
        font-size: 0.8125rem;
    }
}

.btn-end-chat {
    line-height: 1.375rem;
    &.btn-sm{
        img,
        svg {
      
            margin-top: -3px;
        }
    
        i {
            font-size: 1.125rem;
        }
    }
    .circular-loader{
        width: inherit !important;
        height: inherit !important;
    }
    &:hover{
        .circular-loader{
            color:#fff !important;
        }
    }

}


.chat-close-btn{
    padding:0px 5px;
    .cross-icon{
        font-size: 20px;
    }
}