@import '../../../../static/scss/variables';

.chat-icon {
  padding: 0px !important;
  path {
    fill: var(--primary) !important;
  }
}

.chat-list-container {
  // chat list container
  .chat-item {
    // display: grid;
    // grid-template-columns: 4fr 1fr;
    border: 1px solid $lightgray-1 !important;
    border-radius: 8px;
    padding: 0 !important;
    .id-container {
      display: flex;
      align-items: center;
    }
    .left-section {
      .chatname {
        &-title {
          font-family: 'Lato';
        }
        .chat-icon {
          width: 1.7rem !important;
          height: 1.7rem !important;
          border-radius: 1px !important;
          background: none !important;
          padding: 0px !important;
          path {
            fill: var(--primary) !important;
          }
        }
        .language {
          color: $darkgray-2;
          font-size: 12px;
          line-height: 14px;
          .lang-pill {
            color: #fff;
            padding: 4px 3px;
            font-size: 9px;
          }
        }
        .copy-button {
          position: relative !important;
          display: inline-block;
          .copy-button-wrapper {
            height: inherit !important;
            line-height: unset !important;
            width: 100% !important;
            padding-left: 3px !important;
            .copy-button-icon {
              right: -15px !important;
              svg {
                background-color: transparent;
                color: $primary;
              }
            }
            .copied-text-svg {
              right: -10px !important;
            }
          }
        }

        .lang-idle-pill-container {
          .idle-time {
            color: $darkgray-2;
            font-size: 13px;
            .idle-pill {
              padding: 3px;
            }
          }
        }
      }
      .msg-time {
        .unread-msg-count {
          // position: absolute;
          // top: -6px;
          // right:-7px;
          height: max-content;
          padding: 4px 5px !important;
          font-size: 9px !important;
          background-color: $success !important;
          color: #fff;
        }
      }
    }
  }

  .action-btn-container {
    // width: 65%;
    flex: 1;
    display: inline-block;
    .btn-font-size {
      font-size: 10px;
    }
  }
}
